@media screen and (min-width: 1366px) and (orientation: landscape) {
 * {
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
}

html {
	width: 100%;
	height: 100%;
}

body {
	width: 100%;
	height: 100%;
	font-family: Sans-Serif;
	font-size: 10pt;
	letter-spacing: 1px;
	overflow-y: scroll;	
	overflow-x: hidden;
}

.Clickable {
	cursor: pointer;
	opacity: 1;
}

.Clickable:hover {
	opacity: 0.9,
}

::-webkit-scrollbar {
    width: 4px;
    margin-right: 4px;
    padding-right: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

::-webkit-scrollbar-thumb {
    background-color: #333;
}

::-webkit-scrollbar-track {
    background-color: #666;
}


.Filter::-webkit-scrollbar {
    width: 4px;
    margin-right: 4px;
    padding-right: 3px;
	background-color: #000;
}

.Filter::-webkit-scrollbar-thumb:hover {
    background-color: #000;
}

.Filter::-webkit-scrollbar-thumb {
    background-color: #000;
}

.Filter::-webkit-scrollbar-track {
    background-color: #000;
}



.LandingAudioPlaylistItems::-webkit-scrollbar {
    width: 4px;
    margin-right: 4px;
    padding-right: 3px;
}

.LandingAudioPlaylistItems::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.LandingAudioPlaylistItems::-webkit-scrollbar-thumb {
    background-color: #333;
}

.LandingAudioPlaylistItems::-webkit-scrollbar-track {
    background-color: #fff;
}

#root {
	width: 100%;
	height: 100%;
	min-height: 100%;
}

.Clear {
	clear: both;
}

.Wrapper {
	width: 100%;
	min-height: 100%;
	min-width: 1000px;
	margin: 0 auto;
	background-color: #fdfdfd;
}

.fa-spotify {
	font-size: 113%;
}

.fa-apple {
	font-size: 108%;
}

.fa-amazon {
	font-size: 95%;
	margin-bottom: -6px;
}

.fa-user {
	font-size: 105%;
}

.fa-arrow-right, .fa-arrow-left {
	top: -1px;
}

.fa {
	vertical-align: middle;
}

.ContentWrapper {
	width: 100%;
	min-height: 100%;
	height: 100%;
	padding-bottom: 80px;
	padding-top: 80px;
	
}

.Loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
  	align-items: center;
  	justify-content: center;
}

.Loader img {
	max-width: 100%;
	height: auto;
	margin: 0 auto;
}

/***************************************************************************/
/*         Footer														   */
/***************************************************************************/

.Footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	min-width: 1000px;
	padding: 5px 0;
	z-index: 2;
	border-top: 1px solid #ddd;
	height: 40px;
	background-color: rgba(255,255,255,0.9);
}

.FooterMenu {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 40px;
	line-height: 40px;
}

.FooterMenuItem {
	display: inline-block;
	font-family: proxima_nova_ltsemibold;
	margin: 0 10px;
	font-size: 7pt;
	text-transform: uppercase;
	cursor: pointer;
}

.FooterMenu a {
	color: #333;
}

.FooterMenuItem:hover {
	font-style: italic;
}

.FooterCopyright {
	display: inline-block;
	font-family: proxima_nova_ltsemibold;
	margin: 0 40px;
	font-size: 7pt;
	text-transform: uppercase;
	color: #999;
}

.SocialMenu {
	display: inline-block;
	line-height: 40px;
	height: 40px;
	padding-right: 20px;
}

.SocialMenuItem {
	display: inline-block;
	text-transform: uppercase;
	margin: 0 5px;
	line-height: 40px;
}

.SocialMenuItem a {
	color: #333;
	width: 100%;
	text-decoration: none;
	transition: color 0.3s, opacity 0.3s;
	font-size: 11pt;
	opacity: 1;
	padding: 2px;
}

.SocialMenuItem a:active {	
	opacity: 0.6;
}

.FooterClear {
	position: absolute;
	bottom: 0;
	left: 0px;
	width: 100%;
	height: 0;
	background-color: rgba(255,255,255,0.9);
}

.Subscribe {
	position: absolute;
	top: 0;
	right: 5px;
	bottom: -1px;
	line-height: 40px;
}

.Subscribe input {
	font-size: 8pt;
	padding: 2px 5px;
	border: 1px solid #aaa;
	background-color: #fcf7ff;
	outline: none;
	display: inline-block;	
}

.SubscribeButton {
	font-family: proxima_nova_ltsemibold;
	cursor: pointer;
	font-size: 7pt;
	margin: 0 20px;
	padding: 0 5px;
	display: inline-block;
	text-transform: uppercase;
	transition: all 0.3s;
}

.SubscribeButton:hover {
	font-style: italic;
}

.SubscribeButton:active {
	background-color: #333;
}

#message {
	display: inline-block;
	line-height: 40px;
	font-size: 6pt;
	text-transform: uppercase;
	color: red;
}

iframe#shop {
	width: 100%;
	height: 300vh;
	border: none;
	position: absolute;
}

.Page {
	margin: 0 auto;
	width: 600px;
	padding-bottom: 200px;
}

.PageTitle {
	text-align: center;
	font-size: 25pt;
	line-height: 50px;
	font-family: proxima_nova_ltsemibold;
	text-transform: uppercase;
	color: #333;
}

.PageContent {
	text-align: left;
	letter-spacing: 0;	
}

.PageContent p {
	margin: 10px 0;
	line-height: 20px;
}

.PageContent p strong {
	padding-top: 20px;
	display: block;
}

.PageContent p a {
	color: #555;
}

.PageContent p.justify {
	text-align: justify;
}

.PageContent p.has-text-align-center {
	text-align: center;
}

.has-large-font-size {
	font-size: 14pt;
}


.has-larger-font-size {
	font-size: 18pt;
}

/***************************************************************************/
/*         Landing with multiple records & player						   */
/***************************************************************************/

.Landing3 {
	width: 100%;
	height: 100%;	
	margin: 0 auto;
}

.LandingLoader {
	width: 100%;
	text-align: center;
}

.LandingArrowLeft {
	background-color: #333;
	color: #eee;
	position: fixed;
	bottom: 50%;
	font-size: 40pt;
	left: -50px;
	cursor: pointer;
	transition: color 0.3s, background-color 0.3s;
	height: 100px;
	width: 100px;
	text-align: right;
	border-radius: 50px;
	padding-right: 15px;
	line-height: 87px;
	box-shadow: -2px -2px 3px 1px rgba(0,0,0,0.1);
}

.LandingArrowRight {
	background-color: #333;
	color: #eee;
	position: fixed;
	bottom: 50%;
	font-size: 40pt;
	right: -50px;
	cursor: pointer;
	transition: color 0.3s, background-color 0.3s;
	height: 100px;
	width: 100px;
	border-radius: 50px;
	padding-left: 15px;
	line-height: 87px;
	box-shadow: -2px -2px 3px 1px rgba(0,0,0,0.1);
}

.LandingArrowLeft:hover {
	color: orange;
}

.LandingArrowRight:hover {
	color: orange;
}

.LandingArrowText {
	font-size: 13pt;
	padding: 0 10px;
	line-height: 30px;
}

.LandingReleases {
	width: 100%;	
	height: calc(100% - 200px);
}

.LandingRelease {
	width: calc(100% - 200px);
	margin: 0 auto;
	height: calc(100vh - 250px);
}

.LandingReleaseCover {
	display: inline-block;
	width: 45%;
	height: 100%;
	margin-right: 30px;
	cursor: pointer;
	vertical-align: top;
	transition: opacity 0.3s;
}

.LandingReleaseCover:hover {
	opacity: 0.8;
}

.LandingReleaseCoverMain {
	width: 100%;
	height: 100%;
	background-position: left top;
	background-size: contain;
	background-repeat: no-repeat;
}

.LandingReleaseText {
	display: inline-block;
	vertical-align: top;
	width: calc(55% - 30px);	
}

.LandingReleaseTitle {
	font-size: 35pt;
	line-height: 45px;
	font-family: 'proxima_nova_ltsemibold';
}

.LandingReleaseArtist {
	font-size: 20pt;
	line-height: 40px;
	color: #777;
	font-family: 'proxima_nova_ltsemibold';
	padding-left: 20px;
}

.LandingReleaseArtistName::after {
	content: " & ";
}

.LandingReleaseArtistName:last-child::after {
	content: "";
}

.LandingReleaseArtistName:first-child::before {
	content: " ";
}

.LandingReleaseLinks {
	margin: 0 0 20px 0;
}

.LandingReleaseLink {
	display: inline-block;
	font-size: 15pt;
	margin: 0 10px 0 0;
}

.LandingReleaseLink  a {
	color: #333;
	text-decoration: none;
	transition: all 0.3s;
}

.LandingReleaseLink a img {
	top: 7px;
	opacity: 1;
	transition: opacity 0.3s;
}

.LandingReleaseLink  a:hover {
	color: #ccc;
}

.LandingReleaseLink  a:hover  img{
	opacity: 0.7;
}

.LandingReleaseButtons {
	bottom: 0;
}

.LandingReleaseButton  {
	display: inline-block;
	border: 1px solid black;
	height: 40px;
	line-height: 38px;
	padding: 0 15px;
	margin-right: 10px;
	cursor: pointer;
	font-weight: bold;
	background-color: white;
	color: black;
}

.LandingReleaseButton:hover {
	font-style: italic;
	background-color: black;
	color: white;
}

.LandingAudioPlaylistItems {
	max-height: calc(100vh - 380px);
	overflow-y: scroll;
}

.LandingPlayer {	
	height: 100px;
	width: 1300px;
	position: fixed;
	bottom: 50px;
	left: calc(50% - 650px);
	padding: 0;
	background-color: rgba(255,255,255,0.99);
	border: 1px solid #ccc;
	z-index: 100;
}

.LandingPlayerLeft {
	width: 100px;
	max-width: 150px;
	height: calc(100% - 20px);
	margin: 10px 10px 10px 1%; 
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left top;
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
}

.LandingPlayerRight {
	width: calc(99% - 110px);
	height: 100%;
	display: inline-block;
	vertical-align: top;	
}

.AudioPlaylistLanding2 {
	height: 100%;
	display: inline-block;
	vertical-align: top;
}

.AudioPlaylistLandingHiddenPlayer2 {
	display: none;
}

.AudioPlaylistLandingControls2 {
	padding: 10px 0;
	height: 100%;
	width: 129px;
	text-align: center;
	z-index: 2;
}

.AudioPlaylistLandingButton2 {
	display: inline-block;
	vertical-align: top;
	margin: 0 1px;
	border: 1px solid #333;
	width: 41px;
	text-align: center;
	cursor: pointer;
	line-height: 39px;
	height: 41px;
	margin-bottom: 10px;
	font-size: 10pt;
	background-color: #fff;
}

.AudioPlaylistLandingTime2 {
	position: absolute;
	left: 0;
	bottom: 10px;
	height: 20px;
	line-height: 18px;
	width: 60px;
	text-align: center;
	background-color: #fff;
	margin-right: 2px;
	border: 1px solid #333;
	pointer-events: none;
	font-size: 8pt;
}

.AudioPlaylistLandingVolumeBarContainer2 {
	position: absolute;
	right: 0;
	bottom: 10px;
	width: 60px;
	height: 20px;
	background-color: #fff;
	
	border: 1px solid #333;
	cursor: pointer;
}

.AudioPlaylistLandingVolumeBarContainer2 .fa{
	mix-blend-mode: difference;
	color: white;
	padding: 0 5px;
}

.AudioPlaylistLandingVolumeBar2 {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-color: #333;
}

.AudioPlaylistProgressBarContainer2 {
	vertical-align: middle;
	width: calc(100% - 160px);
	height: 41px;
	background-color: #fff;
	margin-right: 2px;
	border: 1px solid #333;
	cursor: pointer;
	overflow: hidden;
	position: absolute;
	top: 10px;
	left: 150px;
}

.AudioPlaylistProgressBar2 {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-color: #333;	
}

.waveform2 {	
	position: relative;
	width: calc(100% - 200px);
	cursor: pointer;
	display: inline-block;
	margin-left: 30px;
	padding: 10px 0;
}

.LandingPlayerTitle {
	position: absolute;
	left: 190px;
	bottom: 15px;
	z-index: 3;
	pointer-events: none;
	letter-spacing: 2px;
}

.LandingPlayerNowPlaying {
	font-size: 7pt;
	text-align: left;
	background-color: rgba(255,255,255,0.7);
	padding: 5px;
	display: inline-block;
}

.LandingPlayerArtistAndSong {
	font-size: 10pt;
	text-align: left;
	font-style: italic;
	right: 10px;
	background-color: rgba(255,255,255,0.85);
	padding: 5px;
}

/***************************************************************************/
/*         HEADER + MENU												   */
/***************************************************************************/

.MobileMenu {
	display: none;
}

.MenuWrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-width: 1000px;
	z-index: 2;
	height: 40px;
	background-color: rgba(0,0,0,0.9);	
}

.MenuWrapper .Menu .MenuLogo {
	background-size: cover;
	background-position: center;
	width: 60px;
	height: 60px;
	line-height: 40px;
	display: inline-block;
	transition: opacity 0.3s, top 0.3s;
	cursor: pointer;
	vertical-align: middle;
	margin: 0 15px;
	position: relative;
	top: -12px;
	z-index: 6;
}

.MenuLogoRJ {
	background-size: cover;
	background-position: center;
	width: 60px;
	height: 32px;
	line-height: 40px;
	display: inline-block;
	transition: opacity 0.3s, top 0.3s;
	cursor: pointer;
	vertical-align: middle;
	margin: 4px 15px;
	position: relative;
	top: 0;
	z-index: 6;
	filter: invert();
}

.MenuWrapper .Menu .MenuLogo:hover {
	top: -7px;
}

.MenuWrapper .Menu {	
	position: absolute;
	left: 0px;
	top: 0;
	margin-bottom: 2px;
	text-align: center;
}

.MenuWrapper .Menu .MenuItem  {	
	display: inline-block;	
	padding: 0 5px;
	line-height: 40px;
	opacity: 1;
	font-size: 11pt;
	
	transition: color 0.3s, font-style 0.3s, background-color 0.3s;
	cursor: pointer;	
	vertical-align: top;
}

.MenuWrapper .Menu .MenuItem a {
	font-family: proxima_nova_ltsemibold;
	text-transform: uppercase;
	color: #eee;
	text-decoration: none;
	transition: color 0.3s, font-style 0.3s, background-color 0.3s;
	padding: 2px 5px;
}

.MenuWrapper .Menu .MenuItem:hover a img{
    -webkit-filter: invert(0%) !important;
}

.MenuWrapper .Menu .MenuItem:hover {
	font-style: italic;
	opacity: 1;
	background-color: #fff;	
}

.MenuWrapper .Menu .MenuItem:hover a {
	color: #333;
}

.MenuWrapper .Menu .MenuItem:active {	
	opacity: 0.6;
}

.MenuWrapper .ShopMenu {
	position: absolute;
	right: 0;
	top: -2px;
	line-height: 40px;
}

.MenuAnnouncement {
	color: yellow;
	position: absolute;
	left: 460px;
	height: 40px;
	line-height: 32px;
}

.MenuWrapper .ShopMenu .ShopMenuItem {
	display: inline-block;
	margin: 0 5px;
	line-height: 40px;
}

.MenuWrapper .ShopMenu .ShopMenuItem a {
	color: #eee;
	width: 100%;
	text-decoration: none;
	transition: color 0.3s, opacity 0.3s;
	font-size: 15pt;
	opacity: 1;
	padding: 5px;
}

.MenuWrapper .ShopMenu .ShopMenuItem a:hover {
	opacity: 0.8;
	color: orange;
	text-decoration: underline;
}

.MenuWrapper .ShopMenu .ShopMenuItem a:active {	
	opacity: 0.6;
}

.MenuWrapper .Menu .MenuItem  a img {	
	top: 2px;	
	filter: invert(100%) !important;
	-webkit-filter: invert(100%) !important;
}

.MenuWrapperFull .Menu .MenuItem a {
	font-family: proxima_nova_ltsemibold;
	text-transform: uppercase;
	color: #333;
	width: 100%;
	text-decoration: none;
	transition: color 0.3s, font-style 0.3s, background-color 0.3s;
	font-size: 12pt;
	opacity: 1;
	padding: 5px 10px;
}

.MenuWrapperFull .Menu .MenuItem a:hover {
	font-style: italic;
	opacity: 0.8;
	color: white;
	background-color: black;	
}

.MenuWrapperFull .Menu .MenuItem:hover a img{
	filter: invert(0%) !important;
	-webkit-filter: invert(0%) !important;
    
}

.MenuWrapperFull .Menu .MenuItem a:active {	
	opacity: 0.6;
}

.MenuWrapperFull .ShopMenu {
	position: fixed;
	right: 0;
	top: 0;
	line-height: 40px;
	z-index: 6;
}

.MenuWrapperFull .ShopMenu .ShopMenuItem {
	display: inline-block;
	margin: 0 5px;
	line-height: 40px;
}

.MenuWrapperFull .ShopMenu .ShopMenuItem a {
	color: #333;
	width: 100%;
	text-decoration: none;
	transition: color 0.3s, opacity 0.3s;
	font-size: 15pt;
	opacity: 1;
	padding: 5px;
}

.MenuWrapperFull .ShopMenu .ShopMenuItem a:hover {
	opacity: 0.8;
	color: orange;
	text-decoration: underline;
}

.MenuWrapperFull .ShopMenu .ShopMenuItem a:active {	
	opacity: 0.6;
}

.CartCount {
	position: absolute;
	font-size: 7pt;
	color: white;
	right: 0px;
	bottom: 6px;
	font-weight: bold;
	background-color: red;
	height: 12px;
	width: 12px;
	border-radius: 6px;
	line-height: 12px;
	text-align: center;
}

.CartPopup {
	position: fixed;
	width: 500px;
	height: 200px;
	left: calc(50% - 250px);
	top: calc(30% - 100px);
	background: rgba(255,255,255,0.9);
	border: 1px solid #333;
}

.CartPopupInfo {
	font-style: italic;
	opacity: 0.8;
	color: white;
	background-color: black;
	margin: 20px;
	padding: 10px;
	text-align: center;
}

.CartPopupButtons {
	text-align: center;
}

.CartPopupButton {
	width: 200px;
	padding: 5px 10px;
	margin: 5px auto;
	cursor: pointer;
	background-color: #666;
	color: white;
	opacity: 0.8;
}

.CartPopupButton:hover {	
	opacity: 1;
}

.CartPopupButton a {
	color: white;
	text-decoration: none;
}

/***************************************************************************/
/*         RELEASES														   */
/***************************************************************************/


.Shop {
	width: 100%;
	padding-bottom: 100px;
}

.Filter {
	position: fixed;
	top: 40px;
	left: 0;
	width: 290px;
	height: calc(100vh - 40px);
	border-top: 1px solid #eee;
	background-color: rgba(0,0,0,0.9);
	overflow-y: scroll;
}

.FilterInner {
	position: relative;
	width: 100%;
	height: auto;
	
	padding-bottom: 200px;
}

.Releases {
	top: -40px;
	left: 290px;	
	width: calc(100% - 290px);	
	text-align: center;
	position: relative;
}

.ReleaseWrap {
	display: inline-block;
	vertical-align: top;
	margin: 30px 15px;
}

.Release {
	width: 270px;
	height: 270px;	
	position: relative;
	cursor: pointer;
	transition: all 0.3s;
	opacity: 0.95;
}

.Release:hover {
	opacity: 1;
}

.Release:hover .ReleaseTitle, .Release:hover .ReleaseArtist {
	opacity: 1;
}

.ReleaseTitle {
	font-size: 8pt;
	letter-spacing: 2px;
	position: absolute;
	bottom: -27px;
	left: 0px;
	color: white;
	background-color: black;
	opacity: 0.6;
	width: 100%;
	text-align: center;
	transition: all 0.3s;
	height: 25px;
	line-height: 25px;
}

.ReleaseArtist {
	font-size: 7pt;
	position: absolute;
	bottom: -43px;
	left: 0px;
	color: #333;
	width: 100%;
	transition: all 0.3s;	
}

.ReleasePlayButtonOuter {
	width: 130px;
	height: 130px;
	border-radius: 65px;	
	position: absolute;	
	top: calc(50% - 65px);
	left: calc(50% - 65px);
	border: 1px solid #ddd;
	background-color: rgba(255,255,255,0.5);
	transition: opacity 0.3s;
	opacity: 0;
}

.ReleasePlayButton {
	width: 120px;
	height: 120px;
	border-radius: 60px;
	position: absolute;	
	top: calc(50% - 60px);
	left: calc(50% - 60px);
	border: 10px solid #fff;	
	color: #fff;
	background-color: rgba(0,0,0,0.5);
	font-size: 50pt;
	text-align: center;
	padding: 7px 30px;		
	cursor: pointer;
}

.ReleaseWrap:hover .ReleasePlayButtonOuter {
	opacity: 0.5;
}

.ReleaseWrap:hover .ReleasePlayButtonOuter:hover {
	opacity: 1;
}

.FilterTitle {
	color: #eee;
	font-size: 15pt;
	padding: 0 10%;
	margin: 20px 0 10px 0;
}

.FilterLabel {
	cursor: pointer;
	margin: 5px 10%;
	padding: 10px;
	background-color: #666;
	width: 80%;
	color: #aaa;
}

.FilterLabelSelected {
	border: 1px solid #eee;
	color: #eee;
}

.FilterGenres {
	padding: 0 20px;
}

.FilterGenre {
	display: inline-block;
	background-color: #666;
	border: #666;
	color: #aaa;
	padding: 10px;
	border-radius: 3px;
	margin: 2px;
	cursor: pointer;
	font-size: 8pt;
}

.FilterGenreSelected {
	border: 1px solid #eee;
	color: #eee;
}

.ReactTags__tags {
	height: 100%;
	width: 80%;
	left: 10%;
}

.ReactTags__tagInput {
	display: block;
}

.ReactTags__tagInputField {
	width: 100%;
	padding: 10px;
	margin-top: 5px;
}
.ReactTags__selected {
}

.ReactTags__selected .ReactTags__tag {
	font-family: sans-serif;
	background-color: white;
	font-size: 9pt;
	line-height: 35px;
	color: #333;
	border-radius: 3px;
	vertical-align: middle;
	margin: 2px;	
	display: inline-block;
	padding-left: 10px;
}

.ReactTags__selected .ReactTags__remove {
	color: #333;
	margin-left: 10px;
	line-height: 35px;
	font-size: 12pt;
	cursor: pointer;
	border: 1px solid white;
	border-left: 1px dashed #222;
	padding: 0 5px;
	display: inline-block;
	background-color: rgba(0,0,0,0);
	border-radius: 0 3px 3px 0;
}

.ReactTags__suggestions {
	position: absolute;
	z-index: 5;	
	background: #eee;
	color: #333;
	overflow: hidden;
	cursor: pointer;
	padding: 0;
}

.ReactTags__suggestions li {
	padding: 3px 10px;	
	transition: background-color 0.2s;
	margin: 0 3px;
	box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.1);
}

.ReactTags__activeSuggestion {
	background-color: #fafafa;
}


/***************************************************************************/
/*         RELEASE DETAILS												   */
/***************************************************************************/

.ReleaseDetails {	
	width: 1000px;
	margin: 0 auto;
	padding-bottom: 100px;
}

.ReleaseDetailsCover {
	display: inline-block;
	width: 450px;
	height: 450px;
	margin-right: 50px;	
}

.ReleaseDetailsCoverMain {
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	image-rendering: crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering:   -o-crisp-edges;
}

.ReleaseDetailsCoverThumbs {
	margin-top: 20px;
}

.ReleaseDetailsCoverThumb {
	width: 80px;
	height: 80px;
	transition: opacity 0.3;
	opacity: 1;
	display: inline-block;
	vertical-align: top;
	margin-right: 10px;
	margin-bottom: 10px;
	cursor: pointer;
	transition: all 0.3s;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.ReleaseDetailsCoverThumb:hover {
	opacity: 0.8;
}

.ReleaseDetailsText {
	display: inline-block;
	vertical-align: top;
	width: 50%;	
}

.ReleaseDetailsTitle {
	font-size: 16pt;
	font-family: proxima_nova_ltsemibold;
}

.ReleaseDetailsArtist {
	font-style: italic;
	margin: 10px 0 10px 0;
}

.ReleaseDetailsArtist a {
	color: #333;
	text-decoration: none;
}

.ReleaseDetailsArtistName::after {
	content: " & ";
}

.ReleaseDetailsArtistName:last-child::after {
	content: "";
}

.ReleaseDetailsArtistName:first-child::before {
	content: " ";
}

.ReleaseDetailsDate {
	text-align: right;
	font-style: italic;
	margin: 5px 0 10px 0;
}

.ReleaseDetailsContent {
	letter-spacing: 0;
	word-spacing: 0;
	font-size: 9pt;
	text-align: justify;
	margin: 10px 0;	
	line-height: 13pt;
}

.ReleaseDetailsContent p {
	margin: 10px 0;
}

.ReleaseDetailsContent p a {
	color: #333;
}

.ReleaseDetailsVariationsContainer {
	display: inline-block;
}

.ReleaseDetailsVariation {
	margin: 0 0 8px 0;
	
}

.ReleaseDetailsAddToCart, .ReleaseDetailsVariationAddToCart {
	border: 1px solid #333;	
	cursor: pointer;
	transition: all 0.3s;
	display: block;
	padding: 7px 10px;
	width: 160px;
	color: black;
}

.ReleaseDetailsAddToCart:hover, .ReleaseDetailsVariationAddToCart:hover {
	color: white;
	background-color: #333;
	font-style: italic;
}

.ReleaseDetailsBuyNow, .ReleaseDetailsVariationBuyNow {
	display: inline-block;
	vertical-align: bottom;
	font-size: 13pt;
	line-height: 10pt;	
	margin-right: 10px;
}

.ReleaseDetailsVariationName {
	font-family: proxima_nova_ltsemibold;
	display: inline-block;
	text-transform: uppercase;
	font-size: 10pt;
	margin-right: 10px;	
}

.ReleaseDetailsVariationOutOfStock {
	border: 1px solid #333;
	transition: all 0.3s;
	padding: 7px 10px;
	background: #eee;
	width: 160px;
}

.ReleaseDetailsVariationDescription {
	font-size: 7pt;
	letter-spacing: 1px;
	color: #aaa;
	padding: 5px;
	text-align: justify;
}

.ReleaseDetailsPrice, .ReleaseDetailsVariationPrice {
	font-family: proxima_nova_ltsemibold;
	position: absolute;
	right: 10px;
	top: 8px;
	font-size: 10pt;
	text-align: right;
}

.ReleaseDetailsOutOfStock {
	display: inline-block;
	color: red;
	font-size: 10pt;
	font-family: proxima_nova_ltsemibold;
}

.ReleaseDetailsLinks {
	margin: 20px 0;
}

.ReleaseDetailsLink {
	display: inline-block;
	font-size: 22pt;
	margin: 0 15px 0 0;
}

.ReleaseDetailsLink  a {
	color: #333;
	text-decoration: none;
	transition: all 0.3s;
}

.ReleaseDetailsLink a img {
	top: 7px;
	opacity: 1;
	transition: opacity 0.3s;
}

.ReleaseDetailsLink  a:hover {
	color: #ccc;
}

.ReleaseDetailsLink  a:hover  img{
	opacity: 0.7;
}

.ReleaseDetailsPreorder {
	font-size: 9pt;
	padding: 20px 0;
}

.ReleaseDetailsCopyright {
	font-size: 8pt;
	letter-spacing: 1px;
	color: #999;
	margin-top: 40px;
}

/***************************************************************************/
/*         ROSTER														   */
/***************************************************************************/

.Roster {
	width: 100%;
	min-width: 1000px;
	margin: 0 auto;
	padding-bottom: 120px;
	text-align: center;
	position: relative;	
}

.RosterArtist {
	width: 270px;
	height: 270px;
	display: inline-block;
	vertical-align: top;
	margin: 30px 15px;
	position: relative;
	cursor: pointer;
	transition: all 0.3s;
	opacity: 0.95;	
	background-size: contain;
	background-position: center center;
}

.RosterArtist:hover {
	opacity: 1;
}

.RosterArtist:hover .RosterArtistName {
	opacity: 1;
}

.RosterArtistName {
	font-size: 8pt;
	letter-spacing: 3px;
	position: absolute;
	bottom: -32px;	
	color: white;
	background-color: black;
	width: 100%;
	opacity: 0.6;
	transition: all 0.3s;
	height: 30px;
	line-height: 30px;
	z-index: 2;
}

.RosterArtistDetails  {
	width: 800px;
	margin: 70px auto 0 auto;
	padding-bottom: 120px;
	position: relative;
}

.RosterArtistDetailsCover {
	display: inline-block;
	width: 440px;
	margin-right: 50px;
	margin-left: 10px;
}


.RosterArtistDetailsCover img {
	max-width: 100%;
	height: auto;
}

.RosterArtistDetailsName {
	font-size: 16pt;
	font-family: proxima_nova_ltsemibold;
}

.RosterArtistDetailsText {
	display: inline-block;
	vertical-align: top;
	width: 290px;
	margin-right: 10px;
}

.RosterArtistDetailsContent {
	letter-spacing: 0;
	word-spacing: 0;
	font-size: 10pt;
	text-align: justify;
	margin: 10px 0;	
	line-height: 13pt;
}

.RosterArtistDetailsContent p {
	margin: 10px 0;
}

.RosterArtistDetailsContent p a {
	color: #333;
}

.RosterArtistDetailsReleases {
	margin-top: 50px;
	width: 100%;
	text-align: center;
	padding-top: 30px;
	border-top: 1px solid #aaa;
}

.RosterArtistDetailsReleases a {
	color: #333;
	text-decoration: none;
}

.RosterArtistDetailsReleasesTitle {
	text-transform: uppercase;
	font-size: 10pt;
	margin-bottom: 20px;
}

.RosterArtistDetailsRelease {
	margin: 10px;
	width: 140px;
	height: 165px;
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
}

.RosterArtistDetailsRelease:hover .RosterArtistDetailsReleaseName {
	font-style: italic;
}

.RosterArtistDetailsReleaseCover {
	width: 140px;
	height: 140px;
	background-position: center center;
	background-size: cover;
}

.RosterArtistDetailsReleaseName {
	height: 25px;
	margin-top: 5px;
	line-height: 13px;
	font-size: 7pt;
	text-align: center;
	width: 100%;
}

/***************************************************************************/
/*         AUDIO PLAYER													   */
/***************************************************************************/

.AudioPlaylist {
	width: 100%;
	margin: 30px 0;
}

.AudioPlaylistHiddenPlayer {
	display: none;
}

.AudioPlaylistInfoTitle {
	text-align: center;	
	mix-blend-mode: difference;
	color: white;
	z-index: 2;
}

.AudioPlaylistControls {
	line-height: 18px;
	height: 20px;
	font-size: 8pt;
}

.AudioPlaylistButton {
	display: inline-block;
	vertical-align: middle;
	margin-right: 2px;
	border: 1px solid #333;
	width: 30px;
	text-align: center;
	cursor: pointer;
	line-height: 18px;
	height: 20px;
}

.AudioPlaylistProgressBarContainer {
	vertical-align: middle;
	display: inline-block;
	width: 250px;
	height: 20px;
	background-color: #fff;
	margin-right: 2px;
	border: 1px solid #333;
	cursor: pointer;
	overflow: hidden;
	font-size: 7pt;
}

.AudioPlaylistProgressBar {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-color: #333;	
}

.AudioPlaylistTime {
	display: inline-block;
	vertical-align: middle;
	height: 20px;
	width: 60px;
	text-align: center;
	background-color: #fff;
	margin-right: 2px;
	border: 1px solid #333;
	pointer-events: none;
}

.AudioPlaylistVolumeBarContainer {
	display: inline-block;
	float: right;
	vertical-align: middle;
	width: 75px;
	height: 20px;
	background-color: #fff;
	
	border: 1px solid #333;
	cursor: pointer;
}

.AudioPlaylistVolumeBarContainer .fa{
	mix-blend-mode: difference;
	color: white;
	padding: 0 5px;
}

.AudioPlaylistVolumeBar {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-color: #333;
}

.AudioPlaylistItems {
	margin-top: 5px;
	border-top: 1px solid #555;
}

.AudioPlaylistItem {
	cursor: pointer;
	border-bottom: 1px solid #555;
	padding: 5px;
	transition: all 0.3s;
	font-size: 8pt;
	letter-spacing: 0.5pt;
}

.AudioPlaylistItem .fa {
	color: #666;
}

.AudioPlaylistItem:hover {
	background-color: #eee;
}

.AudioPlaylistItemTitle {
	margin-left: 3px;
}

.AudioPlaylistItemLength {
	float: right;
	text-align: right;
}

.Cart {
	width: 1300px;
	margin: 0 auto;
	padding-bottom: 200px;
}

.CartImage {
	background-image: url('./assets/empty_cart.png');
	width: 100px;
	height: 200px;
	margin: 0 auto 20px auto;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
}

.Account {
	width: 1300px;
	margin: 0 auto;
	padding-bottom: 200px;
}


/****** REDEEEEEEEEEEEEEM ****/


.Redeem {
	margin: 100px auto;
	padding-bottom: 20px;
	width: 600px;
	text-align: center;
}

.RedeemTitle {
	font-size: 15pt;
	font-family: proxima_nova_ltsemibold;
	text-transform: uppercase;
	color: #333;
}

.RedeemContent {
	text-align: center;
	letter-spacing: 0;	
}

.RedeemContent p {
	margin: 20px 0;
	line-height: 20px;
}

p.spaced {
    padding: 5px 20px;
    font-size: 15pt;
    font-weight: 400;
    color: black;
}

.label {
    display: inline-block;
    text-align: left;
    height: 30px;
    line-height: 30px;
	margin-right: 20px;
    text-transform: uppercase;
    font-size: 9pt;
    font-weight: 400;
    color: black;
    letter-spacing: 2px;
}

.spaced input[type=text] {
    width: 200px;
    height: 30px;
    padding: 5px 10px;
	border: 1px solid #333;
}

.spaced input[type=submit] {
    width: 200px;
    height: 30px;
    padding: 5px 10px;
    margin: 20px 0;
    background: none;
    border: 1px solid black;
    color: black;
    transition: all 0.3s;
    cursor: pointer;
    letter-spacing: 2px;
    font-size: 9pt;

}

.spaced input[type=submit]:hover {
    background-color: #eee;
    color: #ccc;
}

.spaced input[type=submit]:active {
    background-color: black;
    border-color: white;
    color: white;
}

/** LOGO COLORS **/
.Bandcamp a:hover {
	color: teal;
}

.Spotify a:hover {
	color: #84bd00;
}

.ITunes a:hover {
	color: magenta;
}

.Amazon a:hover {
	color: #ff9900;
}

.Youtube a:hover {
	color: red;
}

.Soundcloud a:hover {
	color: #ff7700;
}

.Facebook a:hover {
	color: #3B5998;
}

.Twitter a:hover {
	color: #00aced;
}

.Youtube a:hover {
	color: #ff0000;
}

.Instagram a:hover {
	color: #cd486b;
}

.LandingReleaseLink a img {
	height: 23px;
	image-rendering: pixelated;
}

.LandingReleaseLink a img:hover {
	filter: invert(37%) sepia(99%) saturate(3074%) hue-rotate(228deg) brightness(106%) contrast(104%);
}

.ReleaseDetailsLink a img {
	height: 30px;
	image-rendering: pixelated;
}

.ReleaseDetailsLink a img:hover {
	filter: invert(37%) sepia(99%) saturate(3074%) hue-rotate(228deg) brightness(106%) contrast(104%);
}

/***************************************************************************/
/*         MERCH														   */
/***************************************************************************/

.Merch {
	width: 100%;
	min-width: 900px;
	margin: 0 auto;
	padding-bottom: 20px;
	text-align: center;
	position: relative;
}

.MerchItem {
	width: 270px;
	height: 270px;
	display: inline-block;
	vertical-align: top;
	margin: 30px 15px;
	position: relative;
	cursor: pointer;
	transition: all 0.3s;
	opacity: 0.95;
}

.MerchItem:hover {
	opacity: 1;
}

.MerchItem:hover .MerchItemTitle {
	opacity: 1;
}

.MerchItemTitle {
	font-size: 8pt;
	letter-spacing: 2px;
	position: absolute;
	bottom: -27px;
	left: 0px;
	color: white;
	background-color: black;
	opacity: 0.6;
	width: 100%;
	text-align: center;
	transition: all 0.3s;
	height: 25px;
	line-height: 25px;
}


/***************************************************************************/
/*         MERCH DETAILS												   */
/***************************************************************************/

.MerchItemDetails {	
	width: 1000px;
	margin: 70px auto 0 auto;
	padding-bottom: 20px;
}

.MerchItemDetailsCover {
	display: inline-block;
	width: 450px;
	height: 450px;
	margin-right: 50px;	
}

.MerchItemDetailsCoverMain {
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	image-rendering: crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering:   -o-crisp-edges;
}

.MerchItemDetailsCoverThumbs {
	margin-top: 20px;
}

.MerchItemDetailsCoverThumb {
	width: 80px;
	height: 80px;
	transition: opacity 0.3;
	opacity: 1;
	display: inline-block;
	vertical-align: top;
	margin-right: 10px;
	margin-bottom: 10px;
	cursor: pointer;
	transition: all 0.3s;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.MerchItemDetailsCoverThumb:hover {
	opacity: 0.8;
}

.MerchItemDetailsText {
	display: inline-block;
	vertical-align: top;
	width: 50%;	
}

.MerchItemDetailsTitle {
	font-size: 16pt;
	font-family: proxima_nova_ltsemibold;
}

.MerchItemDetailsContent {
	letter-spacing: 0;
	word-spacing: 0;
	font-size: 9pt;
	text-align: justify;
	margin: 10px 0;	
	line-height: 13pt;
}

.MerchItemDetailsContent p {
	margin: 10px 0;
}

.MerchItemDetailsContent p a {
	color: #333;
}

.MerchItemDetailsSizeContainer {
	display: inline-block;
	margin: 10px 0;
}

.MerchItemDetailsSelectSize {
	
}

.MerchItemDetailsSize {
	display: inline-block;
	line-height: 20px;
	margin: 2px;
	border: 1px solid black;
	padding: 6px;
	cursor: pointer;
	font-weight: bold;
	font-size: 9pt;
}

.MerchItemDetailsSize.Selected {
	background-color: black;
	color: white;
}

.MerchItemDetailsSize:hover {
	background-color: grey;
	color: white;
}

.MerchItemDetailsAddToCart {
	border: 1px solid #333;	
	cursor: pointer;
	transition: all 0.3s;
	display: block;
	padding: 7px 10px;
	width: 160px;
	color: black;
}

.MerchItemDetailsAddToCart:hover {
	color: white;
	background-color: #333;
	font-style: italic;
}

.MerchItemDetailsBuyNow {
	display: inline-block;
	vertical-align: bottom;
	font-size: 13pt;
	line-height: 10pt;	
	margin-right: 10px;
}

.MerchItemDetailsPrice {
	font-family: proxima_nova_ltsemibold;
	position: absolute;
	right: 10px;
	top: 8px;
	font-size: 10pt;
	text-align: right;
}

}

@media screen and (min-width: 1920px) and (orientation: landscape) {
	.LandingReleaseTitle {
		font-size: 50pt;
		line-height: 55px;
		font-family: 'proxima_nova_ltsemibold';
	}

	.LandingReleaseArtist {
		font-size: 25pt;
		line-height: 60px;
	}

	.LandingAudioPlaylistItems {
		max-height: calc(100vh - 470px);
		overflow-y: scroll;
	}

	.FilterInner {
		padding-bottom: 0px;
	}
}

@media screen and (min-width: 2500px) and (orientation: landscape) {
	.LandingReleaseTitle {
		font-size: 70pt;
		line-height: 80px;
		font-family: 'proxima_nova_ltsemibold';
	}

	.LandingReleaseArtist {
		font-size: 30pt;
		line-height: 60px;
		color: #777;
		font-family: 'proxima_nova_ltsemibold';
		padding-left: 20px;
	}
}
